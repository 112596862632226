import $vuetify from '@/lang/es_vuetify';

export default {
  actions: 'Acciones',
  accessDenied: '!Acceso negado!',
  browseError: 'Error consultando {0}: ',
  cloneError: 'Error creando {0}: ',
  cloneSuccess: '{0} creado.',
  close: 'Cerrar',
  createError: 'Error creando{0}: ',
  createSuccess: '{0} creado.',
  editError: 'Error actualizando {0}: ',
  editSuccess: '{0} actualizado.',
  delete: 'eliminar',
  delete_access_groups_warning: 'Tambien se eliminarán los Horarios e Integrantes relacionados con este Grupo.',
  delete_classes_warning: 'Tambien se eliminarán los Horarios e Integrantes relacionados con este Actividad.',
  delete_customers_warning: 'Tambien se eliminarán las Citas, Tareas, Medidas y Huellas Digitales relacionadas con este Cliente.',
  delete_employees_warning: 'Tambien se eliminarán las Asistencias relacionadas con esta Persona.',
  delete_Error: 'Error deleting {0}: ',
  delete_groups_warning: 'Tambien se eliminarán los Horarios e Integrantes relacionados con este Grupo.',
  delete_inscriptions_warning: 'Tambien se eliminarán los Adeudos, Cobros y Asistencias relacionados con esta Membresía.',
  delete_service_dues_warning: 'Tambien se eliminarán los Cobros relacionados con este Adeudo.',
  deleteSuccess: '{0} eliminado.',
  destroy_activity_categories_error: 'No se puede eliminar este Categoría porque tiene Tipos relacionados.',
  destroy_activity_ees_error: 'No se puede eliminar este Tipo porque tiene Membresías relacionadas.',
  destroy_appointment_categories_error: 'No se puede eliminar esta Categoría porque tiene Citas relacionadas.',
  destroy_appointments_status_error: 'No se puede eliminar este Estado porque tiene Citas relacionadas.',
  destroy_classes_error: 'No se puede eliminar esta Clase porque tiene Asistencias relacionadas.',
  destroy_concepts_error: 'No se puede eliminar este Concepto porque tiene Adeudos y/o Cobros relacionados.',
  destroy_customer_categories_error: 'No se puede eliminar esta Categoría porque tiene Clientes relacionados.',
  destroy_customers_error: 'No se puede eliminar este Cliente porque tiene Facturas y/o Membresías y/o Adeudos y/o Cobros y/o Ventas relacionados.',
  destroy_expense_categories_error: 'No se puede eliminar esta Categoría porque tiene Gastos relacionados.',
  destroy_expenses_error: 'No se puede eliminar esta Categoría porque tiene Gastos relacionados.',  // Test !!!!!
  destroy_sales_persons_error: 'No se puede eliminar este Ejecutivo porque tiene Clientes relacionados.',
  destroy_service_dues_error: 'No se puede eliminar este Adeudo porque tiene Cobros relacionadas.',
  destroy_service_payments_error: 'No se puede eliminar este Cobro porque tiene Facturas relacionadas.',
  destroy_task_categories_error: 'No se puede eliminar esta Categoría porque tiene Tareas relacionadas.',
  destroy_task_status_error: 'No se puede eliminar este Estado porque tiene Tareas relacionadas.',
  doesNotExist: '{0} no existe.',
  dupEntryError: '{0} duplicado.',
  fieldRequired: 'Campo requerido.',
  invalidData: '!Datos inválidos!',
  language: 'Idioma',
  myAccount: 'Mi cuenta',
  routeNotFound: '!Página no encontrada!',
  save: 'Guardar',
  search: 'Buscar',
  searchIn: 'Buscar en',
  update: 'Actualizar',
  validNumber: 'Ingrese un número válido.',

  // Auth related:
  Auth: {
    alreadyRegistered: '¿Ya tiene una cuenta?',
    emailVerified: '!Su correo ha sido verificado!',
    emailPasswordResetLink: 'Enviar correo para restablecer contraseña',
    forgotPassword: '¿Olvidó su contraseña?',
    forgotPasswordError: 'Error enviando correo para restablecer contraseña ',
    forgotPasswordText: 'No hay problema. Sólo ingrese su curreo electrónico y le enviaremos un correo para que elija una nueva contraseña.',
    invalidSignature: 'Error verificando correo: Firma inválida',
    login: 'Iniciar sesión',
    loginError: 'Error iniciando sesión: ',
    logout: 'Cerrar sesión',
    logoutError: 'Error cerrando sesión: ',
    minChars: 'Mínimo {0} caracteres.',
    notifyError: 'Error reenvianod notificación: ',
    notifyText: '!Gracias por crear su cuenta! Antes de empezar, ¿podría por favor verificar su correo electrónico dando click a la liga que le acabamos de enviar a su correo? Si por alguna razón no lo recibió, con gusto le enviaremos otro.',
    passwordHint: 'Al menos {0} caracteres.',
    passwordReset: 'Su contraseña se ha restablecido.',
    register: 'Crear cuenta',
    registerError: 'Error al crear cuenta: ',
    resetLinkEmailed: 'Le hemos eniado por correo la liga para restablecer su contraseña.',
    resetPasswordError: 'Error al restablecer contraseña: ',
    resendVerification: 'Reenviar correo de verificación',
    resetPassword: 'restablecer contraseña',
    verificationResent: 'Le hemos reenviado un correo con una liga de verificación nueva a la dirección que nos indicó cuando creó su cuenta.',
    verifyError: 'Error al verificar correo electrónico: ',
    validEmail: 'El campo debe ser un correo electrónico válido.',
    wantToRegister: '¿desea crear una cuenta?',
  },

  // Column labels.
  Cols: {
    activities: {
      id: 'Id',
      name: 'Nombre',
      note: 'Observaciones',
    },

    appointment_status: {
      id: 'Clave',
      name: 'Nombre',
      note: 'Observaciones',
    },

    appointment_types: {
      id: 'Clave',
      name: 'Nombre',
      note: 'Observaciones',
    },

    appointments: {
      appointment_status: 'Estatus',
      appointment_status_id: 'Estatus',
      appointment_type: 'Tipo de Cita',
      appointment_type_id: 'Tipo de Cita',
      customer: 'Titular',
      customer_id: 'Titular',
      date_time: 'Fecha y Hora',
      description: 'Descripción',
      id: 'Id',
      note: 'Observaciones',
      sales_person: 'Ejecutivo de Ventas',
      sales_person_id: 'Ejecutivo de Ventas',
    },

    attendances: {
      activity: 'Actividad',
      activity_id: 'Actividad',
      check_in_date_time: 'Fecha y Hora de Entrada',
      check_out_date_time: 'Fecha y Hora de Salida',
      customer: 'Cliente',
      customer_id: 'Cliente',
      id: 'Id',
      inscription: 'Membresía',
      inscription_id: 'Membresía',
      instructor: 'Instructor',
      instructor_id: 'Instructor',
      note: 'Observaciones',
    },

    businesses: {
      currency: 'Moneda',
      currency_id: 'Moneda',
      id: 'Id',
      licence_expiration_date: 'Licencia Vigente hasta el',
      licence_time_stamp: 'Licence Time Stamp',
      max_failed_attempts: 'Máximos Intentos Fallidos',
      min_pwd_length: 'Logitud Mínima de Contraseña',
      name: 'Nombre',
      note: 'Observaciones',
      pwd_expiration_days: 'Días para Expiración de Contraseñas',
      service_dues_preadvice_days: 'Días para Corte de Adeudos',
      service_dues_tolerance_days: 'Días de Tolerancia para Adeudos',
      ticket_footer: 'Pie de página para tickets',
      ticket_header: 'Encabezado para tickets',
    },

    clock_ins: {
      check_in_date_time: 'Fecha y Hora de Entrada',
      check_out_date_time: 'Fecha y Hora de Salida',
      employee: 'Empleado',
      employee_id: 'Empleado',
      id: 'Id',
      note: 'Observaciones',
    },

    currencies: {
      code: 'Código',
      decimals_separator: 'Separador de Decimales',
      id: 'Clave',
      name: 'Nombre',
      note: 'Observaciones',
      thousands_separator: 'Separador de Miles',
    },

    customer_types: {
      id: 'Clave',
      name: 'Nombre',
      note: 'Observaciones',
    },

    customers: {
      address: 'Dirección',
      allergies: 'Alergias',
      birthday: 'Fecha de Nacimiento',
      blood_type: 'Tipo de Sange',
      customer_since: 'Cliente desde',
      customer_type: 'Tipo de Cliente',
      customer_type_id: 'Tipo de Cliente',
      emergency_contact: 'Contacto Emergencia',
      emergency_phone: '         Teléfono',
      emergency_relationship: '          Parentesco',
      facebook: 'Facebook',
      id: 'Clave',
      instagram: 'Instagram',
      mail: 'Correo Electrónico',
      marketing_channel: 'Canal de Publicidad',
      medical_alert: 'Alerta Médica',
      name: 'Nombre',
      note: 'Observaciones',
      other_phone: 'Otros Teléfonos',
      other_social_networks: 'Other Social Networks',
      other_sports: 'Otro deporte que Practica',
      phone: 'Teléfono',
      photo_file: 'Photo File',
      practiced_sport: 'Practicado algún Deporte',
      profession: 'Profesión',
      sales_person: 'Ejecutivo de Ventas',
      sales_person_id: 'Ejecutivo de Ventas',
      sector: 'Giro',
      sex: 'Sexo',
      smokes: 'Fuma',
      sport_practiced: 'Describa cual',
      surgery: 'Operación Reciente',
      surgery_description: 'Describa cual',
      tax_code: 'R.F.C.',
      tax_domicile: 'Domicilio Fiscal',
      tax_name: 'Razón Social',
      time_not_exercizing: 'Tiempo sin hacer Ejercicio',
      twitter: 'Twitter',
    },

    departments: {
      id: 'Clave',
      image_file: 'Image File',
      name: 'Nombre',
      note: 'Descripción',
    },

    dues: {
      amount: 'Importe',
      concept: 'Concepto',
      date: 'Fecha',
      id: 'Folio',
      membership: 'Membresía',
      membership_id: 'Membresía',
      note: 'Observaciones',
    },

    employees: {
      address: 'Dirección',
      curp: 'CURP',
      department: 'Departamento',
      dob: 'Fecha de Nacimiento',
      hire_date: 'Fecha de Contratación',
      id: 'Número',
      leave_date: 'Fecha de Baja',
      mail: 'Correo Electrónico',
      name: 'Nombre',
      note: 'Observaciones',
      other_phones: 'Otros Teléfonos',
      phone: 'Teléfono',
      photo_file: 'Photo File',
      position: 'Puesto',
      sex: 'Sexo',
      shift: 'Turno',
      ssn: 'Número IMSS',
      tax_code: 'RFC',
    },

    expense_types: {
      id: 'Clave',
      name: 'Nombre',
      note: 'Observaciones',
    },

    expenses: {
      amount: 'Importe',
      beneficiary: 'Beneficiario',
      concept: 'Concepto',
      date: 'Fecha',
      expense_type: 'Tipo de Gasto',
      expense_type_id: 'Tipo de Gasto',
      id: 'Clave',
      note: 'Observaciones',
    },

    group_schedules: {
      group: 'Grupo',
      group_id: 'Grupo',
      id: 'Id',
      note: 'Observaciones',
      time_from: 'Hora de Inicio',
      time_to: 'Hora de Terminación',
      week_day_id: 'Día',
    },

    groups: {
      activity: 'Actividad',
      activity_id: 'Actividad',
      capacity: 'Cupo',
      id: 'Id',
      instructor: 'Instructor',
      instructor_id: 'Instructor',
      location: 'Ubicación',
      name: 'Nombre',
      note: 'Observaciones',
    },

    inflow_items: {
      id: 'Clave',
      inflow: 'Entrada',
      inflow_id: 'Entrada',
      product: 'Producto',
      product_id: 'Producto',
      quantity: 'Cantidad',
      sequence: 'Secuencia',
    },

    inflows: {
      count_of_items: 'Partidas',
      date: 'Fecha',
      description: 'Descripción',
      id: 'Id',
      note: 'Observaciones',
    },

    instructors: {
      id: 'Id',
      name: 'Nombre',
      note: 'Observaciones',
    },

    inventories: {
      id: 'Clave',
      lead_days: 'Días de suministro',
      location: 'Ubicación',
      note: 'Note',
      product: 'Producto',
      product_id: 'Producto',
      reorder_point: 'Punto de Pedido',
      stock: 'Existencia',
      stock_maximum: 'Existencia Máxima',
      stock_minimum: 'Existencia Mínima',
    },

    measurements: {
      chest: 'Pectoral',
      customer: 'Cliente',
      customer_id: 'Cliente',
      date: 'Fecha',
      dress_size: 'Talla de Vestir',
      fat: '%Grasa',
      height: 'Estatura',
      hips: 'Cadera',
      id: 'Id',
      left_arm: 'Brazo Izquierdo',
      left_calf: 'Pantorrilla Izquierda',
      left_thigh: 'Muslo Izquierdo',
      note: 'Observaciones',
      right_arm: 'Brazo Derecho',
      right_calf: 'Pantorrilla Derecha',
      right_thigh: 'Muslo Derecho',
      waist: 'Cintura',
      weight: 'Peso KG',
    },

    memberships: {
      auto_renewal: 'Renovación automática',
      customer: 'Cliente',
      customer_id: 'Cliente',
      fee: 'Cuota',
      id: 'Clave',
      inscription_fee: 'Inscripción',
      note: 'Observaciones',
      plan: 'Plan',
      plan_id: 'Plan',
      total: 'Total',
      validity_from: 'Vigencia del',
      validity_to: 'Vigencia al',
    },

    outflow_items: {
      id: 'Clave',
      outflow: 'Salida',
      outflow_id: 'Salida',
      product: 'Producto',
      product_id: 'Producto',
      quantity: 'Cantidad',
      sequence: 'Secuencia',
    },

    outflows: {
      count_of_items: 'Partidas',
      date: 'Fecha',
      description: 'Descripción',
      id: 'Id',
      note: 'Observaciones',
    },

    payment_methods: {
      id: 'Clave',
      name: 'Nombre',
      note: 'Descripción',
    },

    payment_types: {
      id: 'Id',
      name: 'Nombre',
      note: 'Observaciones',
      quantity: 'Cantidad',
      unit_of_time: 'Unidad de Tiempo',
    },

    payments: {
      amount: 'Importe',
      amount_tendered: 'Importe recibido',
      change: 'Camvio',
      concept: 'Concepto',
      date: 'Fecha',
      due: 'Adeudo',
      due_id: 'Adeudo',
      id: 'Folio',
      note: 'Observaciones',
      payment_method: 'Forma de Pago',
      payment_method_id: 'Forma de Pago',
    },

    plans: {
      activity: 'Actividad',
      activity_id: 'Actividad',
      fee: 'Cuota',
      id: 'Clave',
      inscription_fee: 'Cuota de Inscripción',
      name: 'Nombre',
      note: 'Observaciones',
      number_of_clases: 'Número de Clases',
      payment_type: 'Tipo de Pago',
      payment_type_id: 'Tipo de Pago',
      tax_rate: 'Tasa de Impuesto',
      tax_rate_id: 'Tasa de Impuesto',
      validity: 'Vigencia',
      validity_id: 'Vigencia',
    },

    products: {
      barcode: 'Código de Barras',
      department: 'Departamento',
      department_id: 'Departamento',
      description: 'Descripción',
      discontinued: 'Descontinuado',
      id: 'Clave',
      image_file: 'Image File',
      keywords: 'Palabras Clave',
      name: 'Nombre',
      note: 'Observaciones',
      precio: 'Precio',
      supplier: 'Supplier',
      tax_rate: 'Tasa de Impuesto',
      tax_rate_id: 'Tasa de Impuesto',
      unit: 'Unidad',
    },

    sale_items: {
      amount: 'Importe',
      id: 'Clave',
      price: 'Precio',
      product: 'Producto',
      product_id: 'Producto',
      quantity: 'Cantidad',
      sale: 'Venta',
      sale_id: 'Venta',
      sequence: 'Sequencia',
    },

    sales: {
      amount: 'Importe',
      amount_tendered: 'Importe recibido',
      change: 'Change',
      count_of_items: 'Partidas',
      customer: 'Cliente',
      customer_id: 'Cliente',
      date: 'Fecha',
      description: 'Descripción',
      id: 'Id',
      note: 'Observaciones',
      payment_method: 'Forma de Pago',
      payment_method_id: 'Forma de Pago',
    },

    sales_persons: {
      address: 'Dirección',
      commission_rate: 'Tasa de Comisión',
      id: 'Id',
      initials: 'Clave',
      mail: 'Correo Electrónico',
      name: 'Nombre',
      note: 'Observaciones',
      other_phones: 'Otros Teléfonos',
      phone: 'Teléfono',
      sales_person_type: 'Tipo de Ejecutivo',
      tax_code: 'RFC',
    },

    task_status: {
      id: 'Clave',
      name: 'Nombre',
      note: 'Observaciones',
    },

    task_types: {
      id: 'Clave',
      name: 'Nombre',
      note: 'Observaciones',
    },

    tasks: {
      completion_date_time: 'Fecha de Terminación',
      contact: 'Contacto',
      customer: 'Cliente',
      customer_id: 'Cliente',
      date_time: 'Fecha y Hora',
      description: 'Descripción',
      id: 'Id',
      mail: 'Correo Electrónico',
      note: 'Note',
      phone: 'Teléfono',
      sales_person: 'Ejecutivo de Ventas',
      sales_person_id: 'Ejecutivo de Ventas',
      task_status: 'Estatus',
      task_status_id: 'Estatus',
      task_type: 'Tipo de Tarea',
      task_type_id: 'Tipo de Tarea',
    },

    tax_rates: {
      id: 'Clave',
      name: 'Nombre',
      note: 'Observaciones',
      rate: 'Tasa',
    },

    users: {
      cannot_change_pwd: 'No Cambiar Contraseña',
      change_pwd_nxt_logon: 'Cambiar Contraseña Sgte. Ingreso',
      disabled: 'Inhabilitada',
      email: 'Correo Electrónico',
      email_verified_at: 'Correo verificado el',
      failed_attempts: 'Accesos Fallidos',
      id: 'Clave',
      initials: 'Initials',
      last_login_date: 'Ultimo Ingreso',
      last_pwd_change_date: 'Ultimo Cambio de Contraseña',
      name: 'Nombre',
      note: 'Observaciones',
      password: 'Contraseña',
      phone: 'Teléfono',
      pwd_never_expires: 'Contraseña sin Expiración',
      remember: 'Recuérdame',
      remember_token: 'Token de Recuérdame',
      role: 'Rol',
      sales_person: 'Restringir a Ejecutivo de Ventas',
      sales_person_id: 'Restringir a Ejecutivo de Ventas',
    },

    validities: {
      id: 'Id',
      name: 'Nombre',
      note: 'Note',
      quantiry: 'Cantidad',
      unit_of_time: 'Unidad de Tiempo',
    },

  },
  // Menu group titles.
  Menu: {
    AccessControl: 'Control de Acceso',
    Activities: 'Actividades',
    Charts: 'Gráficos',
    CRM: 'CRM',
    Finances: 'Finanzas',
    Memberships: 'Membresías',
    Personnel: 'Personal',
    POS: 'Punto de Venta',
    Reports: 'Informes',
    Settings: 'Configuracion',
    Users: 'Cuentas de Usuario',
  },

  // Route titles.
  Routes: {
    tableEjemplo_accionEjemplo: 'Título de Ejemplo para ruta "tableEjemplo.accionEjemplo"',
    _accionEjemplo: 'Título de Ejemplo para ruta "_accionEjemplo"',
    _browse: '{tablePlural}',
    _clone: 'Duplicar {tableSingular}',
    _create: 'Crear {tableSingular}',
    _edit: 'Editar {tableSingular}',
    _delete: 'Eliminar {tableSingular}',
    _emailNotify: 'Correo de notificacion',
    _emailVerify: 'Verificación de correo',
    _forgotPassword: '¿Olvidó su contraseña?',
    _login: 'Iniciar sesión',
    _logout: 'Cerrar sesión',
    _register: 'Crear cuenta',
    _resetPassword: 'Restablecer contraseña',
    _show: 'Mostrar {tableSingular}',
    dummy_chart: 'Gráfico prueba',
    dummy_report: 'Informe prueba',
  },

  // Selects.
  Selects: {
    blood_type: 'A+,A+,A-,A-,O+,O+,O-,O-,B+,B+,B-,B-,AB+,AB+,AB-,AB-',
    role: 'che,CHECADOR ASISTENCIAS,caj,CAJERO,eje,EJECUTIVO DE VENTAS,asi,ASISTENTE,sup,SUPERVISOR,adm,ADMINISTRADOR',
    sex: 'F,Femenino,M,Masculino',
    shift: 'M,Matutino,V,Vespertino,N,Nocturno,X,Mixto',
    unit_of_time: 'M,MESES,D,DÍAS,Y,AÑOS,W,SEMANAS,I,INFINITO',
    week_day_id: '1,DOMINGO,2,LUNES,3,MARTES,4,MIÉRCOLES,5,JUEVES,6,VIERNES,7,SÁBADO',
  },

  // Table labels singular | plural: 
  Tables: {
    activities: 'Actividad | Actividades',
    appointment_status: 'Estatus de Cita | Estatus de Cita',
    appointment_types: 'Tipo de Cita | Tipos de Cita',
    appointments: 'Cita | Citas',
    attendances: 'Asistencia | Asistencias',
    businesses: 'Empresa | Empresas',
    clock_ins: 'Asistencia | Asistencias',
    currencies: 'Moneda | Monedas',
    customer_types: 'Tipo de Cliente | Tipos de Cliente',
    customers: 'Cliente | Clientes',
    departments: 'Departamento | Departamentos',
    dues: 'Adeudo | Adeudos',
    employees: 'Empleado | Empleados',
    expense_types: 'Tipo de Gasto | Tipos de Gasto',
    expenses: 'Gasto | Gastos',
    group_schedules: 'Horario | Horarios',
    groups: 'Grupo | Grupos',
    inflow_items: 'Partida de Entrada | Partidas de Entrada',
    inflows: 'Entrada | Entradas',
    instructors: 'Instructor | Instructores',
    inventories: 'Inventario | Inventarios',
    measurements: 'Medida | Medidas',
    memberships: 'Membresía | Membresías',
    outflow_items: 'Partida de Salida | Partidas de Salida',
    outflows: 'Salida | Salidas',
    payment_methods: 'Forma de Pago | Formas de Pago',
    payment_types: 'Tipo de Pago | Tipos de Pago',
    payments: 'Pago | Pagos',
    plans: 'Plan | Planes',
    products: 'Producto | Productos',
    sale_items: 'Partida de Venta | Partidas de Venta',
    sales: 'Venta | Ventas',
    sales_persons: 'Ejecutivo de Ventas | Ejecutivos de Ventas',
    task_status: 'Estatus de Tarea | Estatus de Tarea',
    task_types: 'Tipo de Tarea | Tipos de Tarea',
    tasks: 'Tarea | Tareas',
    tax_rates: 'Tasa de Impuesto | Tasas de Impuesto',
    users: 'Cuenta de Usuario | Cuentas de Usuario',
    validities: 'Vigencias | Vigencias',
},

  // Vuetify traducciones en español.
  $vuetify
}
